var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loader),expression:"loader"}],staticClass:"mb-0"},[_c('b-card-title',{staticClass:"mb-0 font-weight-bold",attrs:{"title-tag":"h2","align":"center"}},[_c('img',{staticClass:"mx-auto",attrs:{"src":"/images/logo/logo.png","alt":"logo","width":"200"}})]),_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#bb9002","title":"SIGN UP FORM","subtitle":"Kindly provide the information below","shape":"circle","step-size":"xs","finish-button-text":"Submit","back-button-text":"Back","next-button-text":"Continue"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Organization","icon":"feather icon-home","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name of Organization","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name of Organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":"ABC Company Ltd."},model:{value:(_vm.form.organization_name),callback:function ($$v) {_vm.$set(_vm.form, "organization_name", $$v)},expression:"form.organization_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Company Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Contact Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"placeholder":"example@companydomain.com"},model:{value:(_vm.form.contact_email),callback:function ($$v) {_vm.$set(_vm.form, "contact_email", $$v)},expression:"form.contact_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Official Phone Number","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Contact Phone Number","rules":"required|integer:min:11|integer:max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"placeholder":"08012345678"},model:{value:(_vm.form.contact_phone),callback:function ($$v) {_vm.$set(_vm.form, "contact_phone", $$v)},expression:"form.contact_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Company Address","label-for":"website"}},[_c('b-form-input',{attrs:{"id":"website","placeholder":"123 Tom Dick and Harry Close, Lagos, Nigeria"},model:{value:(_vm.form.contact_address),callback:function ($$v) {_vm.$set(_vm.form, "contact_address", $$v)},expression:"form.contact_address"}})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Personal Information","icon":"feather icon-user","before-change":_vm.validationFormAdministrator}},[_c('validation-observer',{ref:"administratorRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false:null,"placeholder":"Enter First Name"},model:{value:(_vm.form.admin_first_name),callback:function ($$v) {_vm.$set(_vm.form, "admin_first_name", $$v)},expression:"form.admin_first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false:null,"placeholder":"Enter Last Name"},model:{value:(_vm.form.admin_last_name),callback:function ($$v) {_vm.$set(_vm.form, "admin_last_name", $$v)},expression:"form.admin_last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Official Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Enter Email Address","state":errors.length > 0 ? false:null},model:{value:(_vm.form.admin_email),callback:function ($$v) {_vm.$set(_vm.form, "admin_email", $$v)},expression:"form.admin_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{staticClass:"text-primary"},[_c('strong',[_vm._v("On successful registration, a notification will be sent to this e-mail")])])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Mobile Number","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|integer:min:11|integer:max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"placeholder":"Enter Phone Number"},model:{value:(_vm.form.admin_phone),callback:function ($$v) {_vm.$set(_vm.form, "admin_phone", $$v)},expression:"form.admin_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Designation","label-for":"designation","rules":"integer:min:11|integer:max:11"}},[_c('b-form-input',{attrs:{"id":"phone2","placeholder":"Your designation at work"},model:{value:(_vm.form.designation),callback:function ($$v) {_vm.$set(_vm.form, "designation", $$v)},expression:"form.designation"}})],1)],1)],1)],1)],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('br'),_c('span',[_vm._v("Already with us? ")]),_c('b-link',{attrs:{"to":"/login"}},[_c('span',[_vm._v(" Sign In")])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }