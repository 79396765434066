<template>

  <div class="justify-content-between align-items-center px-2 py-1">
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="16"
        :lg="16"
      >
        <el-skeleton
          :loading="loading"
          :rows="6"
          animated
        />
        <el-collapse v-model="activeNames">
          <el-collapse-item
            v-for="(row, index) in modules"
            :key="index"
            :name="row.id"
          >
            <template slot="title">
              <h3>{{ `${row.name} - ${row.full_name}` }}</h3>
            </template>
            <div
              v-if="row.packages.length > 0"
            >
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th />
                    <th
                      v-for="(pack, package_index) in row.packages"
                      :key="package_index"
                    >
                      <div align="center">
                        <h1><strong>{{ pack.name }}</strong></h1>
                        <h3>{{ currency }}{{ parseFloat(pack.price).toLocaleString('en-US', { minimumFractionDigits: 2 }) }}</h3>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(feature, feature_index) in row.features"
                    :key="feature_index"
                  >
                    <td width="400">
                      <strong>{{ feature.name }}</strong>
                    </td>
                    <th
                      v-for="(pack, package_index) in row.packages"
                      :key="package_index"
                    >
                      <div align="center">
                        <span v-if="pack.features.includes(feature.id)">
                          <feather-icon
                            size="20"
                            color="#30b036"
                            icon="CheckIcon"
                          />
                        </span>
                        <span v-else>
                          <feather-icon
                            size="20"
                            color="#C03639"
                            icon="XIcon"
                          />
                        </span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td />
                    <td
                      v-for="(pack, package_index) in row.packages"
                      :key="package_index"
                    >
                      <div align="center">

                        <el-radio
                          v-model="row.package"
                          :label="pack"
                          border
                          @input="addToCart(row)"
                        >
                          Add to Cart
                        </el-radio>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <el-empty description="No package is created yet" />
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="8"
        :lg="8"
      >
        <div class="fixed">
          <h3><feather-icon
            size="20"
            icon="ShoppingBagIcon"
          /> My Cart</h3>
          <div v-if="cartItems.length > 0">
            <table
              v-loading="paymentLoader"
              class="table table-bordered"
            >
              <thead>
                <tr>
                  <th />
                  <th>Item</th>
                  <th>Cost ({{ currency }})</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, item_index) in cartItems"
                  :key="item_index"
                >
                  <td width="20">
                    <span
                      style="cursor: pointer"
                      @click="removeRow(item_index)"
                    >
                      <feather-icon
                        color="#f00000"
                        size="20"
                        icon="TrashIcon"
                      />
                    </span>
                  </td>
                  <td>
                    <div>
                      <em>{{ `${item.moduleName} - ${item.packageName}` }}</em>
                    </div>
                  </td>
                  <td>{{ parseFloat(item.price).toLocaleString('en-US', { minimumFractionDigits: 2 }) }}</td>
                </tr>
                <tr v-if="grandTotal > 0">
                  <td colspan="2">
                    <strong>Sub Total</strong>
                  </td>
                  <td align="right">
                    <strong>{{ parseFloat(subTotal).toLocaleString('en-US', { minimumFractionDigits: 2 }) }}</strong>
                  </td>
                </tr>
                <tr v-if="subscription !== null">
                  <td colspan="2">
                    <strong>Amount Paid</strong>
                  </td>
                  <td align="right">
                    <strong style="color: green">{{ parseFloat(subscription.paid).toLocaleString('en-US', { minimumFractionDigits: 2 }) }}</strong>
                  </td>
                </tr>
                <tr v-if="grandTotal > 0">
                  <td colspan="2">
                    <strong>Total Payable</strong>
                  </td>
                  <td align="right">
                    <h4><strong style="color: red">{{ parseFloat(grandTotal).toLocaleString('en-US', { minimumFractionDigits: 2 }) }}</strong></h4>
                  </td>
                </tr>
                <tr v-if="!isSaved">
                  <td colspan="3">
                    <b-button
                      variant="outline-primary"
                      @click="submit()"
                    >
                      Continue
                    </b-button>
                  </td>
                </tr>
                <tr v-if="isSaved">
                  <td colspan="3">
                    <div
                      v-if="subscription.paid > 0 && grandTotal < 1"
                      align="center"
                    >
                      <img
                        src="/images/logo/paid.png"
                        alt="logo"
                        width="150"
                      >
                    </div>
                    <div v-else>

                      <label>Payment details will be sent to the email below</label>
                      <el-input
                        v-model="form.email"
                        placeholder="Enter Email"
                        type="email"
                        style="width: 100%;"
                      />
                      <hr>
                      <card-payment
                        :form="form"
                        :currency="currency"
                        @paid="fetchSubscriptionDetails"
                      />
                      <!-- <card-payment
                        v-if="validEmail(form.email)"
                        :form="form"
                        :currency="currency"
                        @paid="fetchSubscriptionDetails"
                      /> -->
                    </div>
                  </td>
                </tr>
                <!-- <tr v-if="isSaved">
                  <td colspan="3">

                    <card-payment
                      v-if="validEmail(form.email)"
                      :form="form"
                      :currency="currency"
                      @paid="checkPaymentStatus"
                    />
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div v-else>
            <el-empty description="Cart is empty" />
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {
  BButton,
// BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CardPayment from './payment/CardPayment.vue'
import { validEmail } from '@/utils/validate'
import Resource from '@/api/resource'

export default {
  components: {
    CardPayment,
    BButton,
    // BRow,
    // BCol,
    // BFormGroup,
    // BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      loadButon: false,
      paymentLoader: false,
      modules: [],
      selectedModule: {
        id: '',
        standards: [],
      },
      activeNames: [],
      cartItems: [],
      grandTotal: 0.00,
      subTotal: 0.00,
      currency: '₦',
      form: {
        email: '',
        amount: 0,
        discount: 0,
        subscription_id: null,

      },
      isSaved: false,
      successful_payment_made: false,
      subscription: null,
    }
  },
  computed: {
    selectedClient() {
      return this.$store.getters.selectedClient
    },
    allModules() {
      return this.$store.getters.allModules
    },
    userData() {
      return this.$store.getters.userData
    },
  },
  created() {
    this.fetchPackages()
    this.fetchSubscriptionDetails()
    this.form.email = this.userData.email
  },
  methods: {
    validEmail,
    removeRow(detailId) {
      this.cartItems.splice(detailId, 1)
      this.isSaved = false
      this.calculateTotal()
    },
    fetchSubscriptionDetails() {
      const app = this
      app.cartItems = []
      const fetchPaymentDetailsResource = new Resource('subscriptions/fetch-subscription-details')
      fetchPaymentDetailsResource.list()
        .then(response => {
          const { subscription } = response
          if (subscription !== null) {
            app.subscription = subscription
            app.form.subscription_id = subscription.id
            app.isSaved = true
            const { details } = subscription

            details.forEach(detail => {
              app.cartItems.push({
                module_id: detail.available_module_id,
                moduleName: detail.available_module.name,
                package_id: detail.module_package_id,
                packageName: detail.module_package.name,
                price: detail.amount,
              })
            })
            app.calculateTotal()
            // app.form.subscription_id = subscription.id
            // app.checkPaymentStatus()
          }
        })
    },
    itemExists(moduleId) {
      const checkEmptyLines = this.cartItems.filter(
        item => item.module_id === moduleId,
      )
      if (checkEmptyLines.length > 0) {
        return true
      }
      return false
    },
    addToCart(selectedModule) {
      const app = this
      app.isSaved = false
      if (app.itemExists(selectedModule.id)) {
        const objIndex = app.cartItems.findIndex(obj => obj.module_id === selectedModule.id)

        app.cartItems[objIndex] = {
          module_id: selectedModule.id,
          moduleName: selectedModule.name,
          package_id: selectedModule.package.id,
          packageName: selectedModule.package.name,
          price: selectedModule.package.price,
        }
      } else {
        app.cartItems.push({
          module_id: selectedModule.id,
          moduleName: selectedModule.name,
          package_id: selectedModule.package.id,
          packageName: selectedModule.package.name,
          price: selectedModule.package.price,
        })
      }
      app.calculateTotal()
    },
    calculateTotal() {
      const app = this
      const { cartItems } = app
      let subTotal = 0

      cartItems.forEach(item => {
        subTotal += parseFloat(item.price)
      })
      const paid = (app.subscription !== null) ? app.subscription.paid : 0
      const grandTotal = subTotal - parseFloat(paid)
      app.subTotal = subTotal
      app.form.amount = grandTotal
      app.grandTotal = grandTotal
    },
    fetchPackages(load = true) {
      const app = this
      app.loading = load
      const fetchPackageResource = new Resource('packages/fetch-modules')
      fetchPackageResource.list()
        .then(response => {
          const { modules } = response
          modules.forEach(mod => {
            app.activeNames.push(mod.id)
          })

          app.modules = modules
          app.loading = false
        })
    },
    submit() {
      const app = this
      app.loadButon = true
      app.successful_payment_made = false
      const saveSubscription = new Resource('subscriptions/store')
      const param = app.form
      const { cartItems } = app
      param.amount = app.subTotal
      param.cart_items = cartItems
      saveSubscription.store(param)
        .then(response => {
          app.isSaved = true
          app.loadButon = false
          app.subscription = response.subscription
          app.form.subscription_id = response.subscription.id
          app.calculateTotal()
          // app.$message('Action Successful')
          // app.$notify({
          //   title: 'Action Successful',
          //   message: 'The specified project have been created for the client',
          // })
        }).catch(error => {
          app.loadButon = false
          app.$message(error.response.data.error)
        })
    },
    checkPaymentStatus() {
      const app = this
      app.paymentLoader = true
      const saveSubscription = new Resource('subscriptions/successful-payment-status')
      const param = app.form
      saveSubscription.store(param)
        .then(response => {
          app.paymentLoader = false
          if (response.successful_payment !== null) {
            app.successful_payment_made = true
          }
        }).catch(error => {
          app.paymentLoader = false
          app.$message(error.response.data.error)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
</style>
<style scoped>
.fixed {
  position: fixed;
  top: 100px;
  right: 20px;
  width: 480px;
  height: 550px;
  overflow: auto;
  background: #f9f9f9;
  padding: 20px;
}
</style>
